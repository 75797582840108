<script>
import {ref, watch, computed, onMounted} from 'vue';
import {useStore} from 'vuex'

export default {
  components: {
  },
  setup() {
    const store = useStore();

    const isOpen = ref(false);

    watch(isOpen, (newValue, oldValue) => {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else if (!newValue) {
        document.body.style.overflow = 'visible'
      }
    });

    const activeLink = computed(() => store.getters.activeLink);

    onMounted(() => {
      window.addEventListener("resize", () => {
        if (window.matchMedia('(min-width: 1021px)').matches) {
          isOpen.value = false
        }
      });
    })

    return {
      activeLink, isOpen
    };
  }
}

</script>
<template>
  <div class="menu js-scroll opacity-menu scrolled">
    <div class="content">

      <a onclick="gtag('event', 'click_link_header', {'btn_name': 'logo'})" href="/" class="logo">
        <svg viewBox="0 0 199 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M193.653 27.804V26.88H197.097V0.952001H193.653V0H198.273V27.804H193.653Z" fill="#8C47CC"/>
          <path
            d="M175.656 22.288V2.46399H190.244V6.18799H179.66V10.136H183.944C184.915 10.136 185.839 10.22 186.716 10.388C187.594 10.5373 188.378 10.8173 189.068 11.228C189.759 11.62 190.3 12.1893 190.692 12.936C191.103 13.6827 191.308 14.6347 191.308 15.792C191.308 17.0613 191.075 18.116 190.608 18.956C190.16 19.796 189.554 20.468 188.788 20.972C188.042 21.4573 187.183 21.8027 186.212 22.008C185.26 22.1947 184.28 22.288 183.272 22.288H175.656ZM179.66 18.592H183.356C183.823 18.592 184.29 18.564 184.756 18.508C185.242 18.4333 185.671 18.312 186.044 18.144C186.418 17.976 186.716 17.724 186.94 17.388C187.183 17.0333 187.304 16.5667 187.304 15.988C187.304 15.4653 187.202 15.0547 186.996 14.756C186.81 14.4387 186.539 14.2053 186.184 14.056C185.83 13.888 185.419 13.776 184.952 13.72C184.504 13.664 184.028 13.636 183.524 13.636H179.66V18.592Z"
            fill="#8C47CC"/>
          <path d="M167.766 27.804V0H172.414V0.952001H168.942V26.88H172.414V27.804H167.766Z" fill="#8C47CC"/>
          <path class="letter"
                d="M142.803 22.288V7.53198H146.723V13.188H148.963L152.575 7.53198H156.999L152.379 14.476L157.027 22.288H152.519L149.187 16.688H146.723V22.288H142.803Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M132.93 22.484C131.436 22.484 130.13 22.1854 129.01 21.588C127.89 20.972 127.022 20.104 126.406 18.984C125.79 17.8454 125.482 16.5014 125.482 14.952C125.482 13.3654 125.79 12.0027 126.406 10.864C127.04 9.7067 127.908 8.82003 129.01 8.20403C130.13 7.58803 131.427 7.28003 132.902 7.28003C134.395 7.28003 135.692 7.59736 136.794 8.23203C137.895 8.8667 138.754 9.75336 139.37 10.892C139.986 12.0307 140.294 13.3934 140.294 14.98C140.294 16.5294 139.986 17.8734 139.37 19.012C138.772 20.132 137.923 20.9907 136.822 21.588C135.739 22.1854 134.442 22.484 132.93 22.484ZM132.874 18.9C133.751 18.9 134.442 18.7134 134.946 18.34C135.468 17.9667 135.842 17.4814 136.066 16.884C136.308 16.2867 136.43 15.652 136.43 14.98C136.43 14.308 136.308 13.664 136.066 13.048C135.842 12.4134 135.468 11.9 134.946 11.508C134.442 11.0974 133.751 10.892 132.874 10.892C132.034 10.892 131.352 11.0974 130.83 11.508C130.307 11.9 129.924 12.404 129.682 13.02C129.458 13.636 129.346 14.2894 129.346 14.98C129.346 15.652 129.458 16.2867 129.682 16.884C129.924 17.4814 130.307 17.9667 130.83 18.34C131.352 18.7134 132.034 18.9 132.874 18.9Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M110.319 22.288V7.53198H114.239V13.188H116.479L120.091 7.53198H124.515L119.895 14.476L124.543 22.288H120.035L116.703 16.688H114.239V22.288H110.319Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M100.445 22.484C98.9519 22.484 97.6452 22.1854 96.5252 21.588C95.4052 20.972 94.5372 20.104 93.9212 18.984C93.3052 17.8454 92.9972 16.5014 92.9972 14.952C92.9972 13.3654 93.3052 12.0027 93.9212 10.864C94.5559 9.7067 95.4239 8.82003 96.5252 8.20403C97.6452 7.58803 98.9425 7.28003 100.417 7.28003C101.911 7.28003 103.208 7.59736 104.309 8.23203C105.411 8.8667 106.269 9.75336 106.885 10.892C107.501 12.0307 107.809 13.3934 107.809 14.98C107.809 16.5294 107.501 17.8734 106.885 19.012C106.288 20.132 105.439 20.9907 104.337 21.588C103.255 22.1854 101.957 22.484 100.445 22.484ZM100.389 18.9C101.267 18.9 101.957 18.7134 102.461 18.34C102.984 17.9667 103.357 17.4814 103.581 16.884C103.824 16.2867 103.945 15.652 103.945 14.98C103.945 14.308 103.824 13.664 103.581 13.048C103.357 12.4134 102.984 11.9 102.461 11.508C101.957 11.0974 101.267 10.892 100.389 10.892C99.5492 10.892 98.8679 11.0974 98.3452 11.508C97.8225 11.9 97.4399 12.404 97.1972 13.02C96.9732 13.636 96.8612 14.2894 96.8612 14.98C96.8612 15.652 96.9732 16.2867 97.1972 16.884C97.4399 17.4814 97.8225 17.9667 98.3452 18.34C98.8679 18.7134 99.5492 18.9 100.389 18.9Z"
                fill="#022E4E"/>
          <path class="letter" d="M81.2524 22.288V7.53198H91.8924V11.032H85.1724V22.288H81.2524Z" fill="#022E4E"/>
          <path class="letter"
                d="M64.7642 22.288V7.53198H68.6842V13.216H74.2562V7.53198H78.1762V22.288H74.2562V16.716H68.6842V22.288H64.7642Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M57.5521 22.288V7.53203H61.4721V22.288H57.5521ZM59.4841 5.96403C58.8308 5.96403 58.2614 5.76803 57.7761 5.37603C57.2908 4.96536 57.0481 4.38669 57.0481 3.64003C57.0481 3.11736 57.1694 2.67869 57.4121 2.32402C57.6548 1.95069 57.9628 1.67069 58.3361 1.48403C58.7094 1.27869 59.1108 1.17603 59.5401 1.17603C60.1748 1.17603 60.7254 1.39069 61.1921 1.82003C61.6774 2.24936 61.9201 2.83736 61.9201 3.58402C61.9201 4.33069 61.6774 4.91869 61.1921 5.34803C60.7068 5.75869 60.1374 5.96403 59.4841 5.96403Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M40.7837 22.288V7.53198H44.7037V13.216H50.2757V7.53198H54.1957V22.288H50.2757V16.716H44.7037V22.288H40.7837Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M31.4263 22.484C29.877 22.484 28.561 22.176 27.4783 21.56C26.3956 20.944 25.565 20.076 24.9863 18.956C24.4076 17.8174 24.1183 16.492 24.1183 14.98C24.1183 13.4494 24.417 12.1147 25.0143 10.976C25.6116 9.8187 26.4516 8.91336 27.5343 8.26003C28.6356 7.6067 29.933 7.28003 31.4263 7.28003C32.5276 7.28003 33.4983 7.4667 34.3383 7.84003C35.197 8.21336 35.9156 8.73603 36.4943 9.40803C37.0916 10.0614 37.5396 10.836 37.8383 11.732C38.137 12.6094 38.277 13.5707 38.2583 14.616C38.2396 14.8774 38.221 15.1294 38.2023 15.372C38.2023 15.6147 38.1743 15.8574 38.1183 16.1H28.0103C28.0663 16.66 28.225 17.1734 28.4863 17.64C28.7476 18.1067 29.1116 18.48 29.5783 18.76C30.0636 19.0214 30.661 19.152 31.3703 19.152C31.837 19.152 32.2663 19.1054 32.6583 19.012C33.0503 18.9 33.3863 18.732 33.6663 18.508C33.9463 18.284 34.1423 18.0227 34.2543 17.724H38.1183C37.8756 18.8254 37.4183 19.7307 36.7463 20.44C36.093 21.1494 35.2996 21.672 34.3663 22.008C33.433 22.3254 32.453 22.484 31.4263 22.484ZM28.0943 13.356H34.5063C34.4876 12.852 34.357 12.3854 34.1143 11.956C33.8903 11.508 33.545 11.1534 33.0783 10.892C32.6303 10.612 32.0516 10.472 31.3423 10.472C30.633 10.472 30.0356 10.612 29.5503 10.892C29.0836 11.172 28.729 11.536 28.4863 11.984C28.2623 12.4134 28.1316 12.8707 28.0943 13.356Z"
                fill="#022E4E"/>
          <path class="letter"
                d="M0 22.288V2.46399H5.292L10.64 12.04L15.904 2.46399H21.028V22.288H16.996V7.78399L11.368 17.864H9.716L4.004 7.78399V22.288H0Z"
                fill="#022E4E"/>
        </svg>
      </a>

      <nav :class="{active: isOpen}">
        <router-link :class="{active: activeLink === 'section2'}" to="#section2">
          <span onclick="gtag('event', 'click_link_header', {'btn_name': 'Види менінгококу'})" @click="isOpen = false">Види менінгококу</span>
        </router-link>
        <router-link
          :class="{active: activeLink === 'section3' || activeLink === 'section4' || activeLink ===  'section5'}"
          to="#section3">
          <span onclick="gtag('event', 'click_link_header', {'btn_name': 'Менінгокок [Б]'})" @click="isOpen = false">Менінгокок [Б]</span>
        </router-link>
        <router-link :class="{active: activeLink === 'section6'}" to="#section6">
          <span onclick="gtag('event', 'click_link_header', {'btn_name': 'Фактори ризику'})" @click="isOpen= false">Фактори ризику</span>
        </router-link>
        <router-link :class="{active: activeLink === 'section7' || activeLink === 'section8'}" to="#section7">
          <span onclick="gtag('event', 'click_link_header', {'btn_name': 'Про хворобу'})" @click="isOpen = false">Про хворобу</span>
        </router-link>
        <router-link :class="{active: activeLink === 'section9'}" to="#section9">
          <span onclick="gtag('event', 'click_link_header', {'btn_name': 'Часті запитання'})" @click="isOpen = false">Часті запитання</span>
        </router-link>
      </nav>

      <div class="mobile-control" @click="isOpen = !isOpen">
        <img v-if="!isOpen" src="../assets/img/ui/burger.svg" alt="">
        <img v-else src="../assets/img/ui/close.svg" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.menu {
  position: fixed !important;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  background: #F7F3E7;

  .content {
    max-width: calc(1280px + 80px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 26px 40px;


    @media screen and (max-width: 1020px) {
      padding: 22px 40px;
    }

    @media screen and (max-width: 600px) {
      padding: 12px 32px;
    }
    @media screen and (max-width: 500px) {
      padding: 16px 32px;
    }
  }

  nav {
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1020px) {
      position: fixed;
      z-index: 1;
      display: none;

      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      width: 100%;
      height: 100%;

      background-color: #E5DCC0;
      flex-direction: column;
      justify-content: center;
      padding: 0 32px;

      &.active {
        display: flex;
        align-items: center;
        height: calc((var(--vh, 1vh) * 100));
      }
    }

    a:not(.defence-btn) {
      margin-right: 35px;

      color: #393939;
      font-size: 16px;
      font-weight: 400;
      line-height: 132%;
      text-decoration: none;
      will-change: color;
      transition: color 200ms ease-in-out;


      &.active {
        color: #8C47CC;
      }

      &:last-of-type {
        margin-right: 0;
      }

      @media screen and (min-width: 1024px) {
        &:hover {
          color: #FF8DBE;
        }
      }

      &:active {
        color: #8C47CC;
      }

      @media screen and (max-width: 1020px) {
        margin-right: 0;
        max-width: 414px;
        margin-bottom: 48px;
        width: 100%;

        color: #393939;
        font-size: 32px;
        font-weight: 400;
        line-height: 116%; /* 37.12px */
        letter-spacing: 1.28px;
        text-transform: uppercase;
        position: relative;

        span {
          width: 100%;
          display: block;
          padding-right: 28px;

          &:before {
            content: "";
            width: 28px;
            height: 28px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            background-image: url("@/assets/img/ui/arrow.svg");
            background-size: 28px;
            background-repeat: no-repeat;
            background-position: 0 0;
            transition: transform 200ms ease-in-out;
          }
        }


        &:active {
          color: #8C47CC;
          animation: fontGrow 100ms ease-in-out;
          font-weight: 700;

          span {
            &:before {
              background-image: url("@/assets/img/ui/arrow.svg");
              background-size: 28px;
              background-repeat: no-repeat;
              background-position: 0 -28px;
              transform: translateX(7px);
            }
          }

        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media screen and (max-width: 500px) {
          margin-bottom: 24px;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
  }

  .mobile-control {
    display: none;
    position: relative;
    z-index: 2;

    img {
      width: 36px;
      height: auto;
      display: block;

      @media screen and (max-width: 500px) {
        width: 26px;
      }
    }

    @media screen and (max-width: 1020px) {
      display: block;
    }
  }

  .logo {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;

    @media screen and (min-width: 1024px) {
      &:hover {
        .letter {
          fill: #FF8DBE;
        }
      }
    }

    @media screen and (max-width: 600px) {
      max-width: 174px;
      width: 100%;
    }

    &:active {
      .letter {
        fill: #8C47CC;
      }
    }

    svg {
      width: 199px;
      height: auto;
      display: block;

      .letter {
        will-change: fill;
        transition: fill 200ms ease-in-out;
      }
    }
  }

  .btn-wrapper {
    max-width: 414px;
    width: 100%;
    display: none;
    justify-content: flex-start;
    margin-top: 100px;

    @media screen and (max-width: 1020px) {
      display: flex;
    }

    @media screen and (max-width: 500px) {
      margin-top: 0;
      position: absolute;
      max-width: 500px;
      bottom: 40px;
      left: auto;
      right: auto;
      padding: 0 32px;

    }
  }
}

@keyframes fontGrow {
  0% {
    font-weight: 400; /* normal */
  }

  100% {
    font-weight: 700; /* normal */

  }
}
</style>