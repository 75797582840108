<script>
import {onMounted, onUnmounted, ref} from 'vue'
import circleAnimation from '@/components/ui/circle-animation.vue'
import nextArrow from '@/components/ui/next-arrow.vue'
import navigation from "@/components/navigation.vue";
import selfMedication from "@/components/self-medication.vue";


export default {
  components: {
    navigation,
    selfMedication,
    nextArrow,
    circleAnimation,
  },

  setup() {
    const contentItems = ref([
      {
        title: 'Захищає',
        body: 'проти <b>найпоширенішого</b> в Європі та Україні збудника <b>менінгококової інфекції<sup>1,7</sup></b>'
      },
      {
        title: 'Рекомендована',
        body: 'до застосування в&nbsp;країнах <b>ЄС</b>&nbsp;та&nbsp;<b>США<sup>2,3</sup></b>'
      },
      {
        title: 'Призначена<br>як для щеплення',
        body: 'дітей <b>від 2&nbsp;місяців</b>, так&nbsp;і&nbsp;<b>підлітків</b><br>та <b>дорослих<sup>2</sup></b>'
      }
    ])

    return {contentItems}
  }
}
</script>

<template>
  <section class="section section1" id="section1">

    <div class="content">
      <div class="content__left">

        <div class="content__message ">

          <div class="content__message-icon">
            <circleAnimation/>
          </div>

          <p class="content__message-text">Новинка для <b class="content__message-accent">батьків з безкомпромісним
            підходом до безпеки дітей</b> проти інфекційних захворювань</p>
        </div>

        <h1 class="content__title js-scroll scrolled fade-in-title">
          <span class="animation-title ">ВАКЦИНАЦІЯ проти</span>
          <span class="animation-title delay-1">
            менінгокока
            <span class="content__title-border">[</span>
            <span class="content__title-letter">Б</span>
            <span class="content__title-border">]</span>
          </span>
          <span class="animation-title delay-2">вже в Україні!</span>
        </h1>



      </div>

      <div class="content__right">
        <div
          v-for="(item, index) in contentItems"
          :key="index"
          :class="`scrolled fade-in-top-visible delay-${ index + 1}-mob-custom content__item content__item--card${index + 1}`">
          <h3 class="content__item-title" v-html="item.title"></h3>
          <p class="content__item-text" v-html="item.body"></p>
        </div>
      </div>

      <div class="content__arrow">
        <nextArrow :link="`/#section2`"/>
      </div>
    </div>

  </section>
</template>

<style scoped lang="scss">

.content {
  padding-top: 48px;
  padding-bottom: 15vh;

  min-height: calc(100vh - 80px);

  align-items: center;
  justify-content: space-between;

  &__left {
    max-width: 590px;
    width: 100%;
    margin-right: 10px;
  }

  &__right {
    max-width: 590px;
    width: 100%;
    margin-left: 10px;
  }

  &__message {
    display: flex;
    align-items: center;

    &-icon {
      width: 120px;
      margin-right: 16px;
    }

    &-text {
      color: #393939;
      font-size: 20px;
      font-weight: 300;
      line-height: 132%;
      letter-spacing: 0.4px;

      max-width: 294px;
      width: 100%;


    }

    &-accent {
      font-size: 20px;
      font-weight: 600;


    }
  }

  &__title {
    color: var(--black-text, #393939);

    font-size: 52px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 2.08px;
    text-transform: uppercase;

    margin-top: 28px;


    b {
      font-weight: 600;

    }

    &-letter {
      font-weight: 600;
      line-height: 116%;

    }

    &-border {
      font-weight: 100;
      line-height: 116%;
    }
  }

  &__btn {
    width: 276px;
    margin-top: 48px;
  }

  &__item {
    border-radius: 24px;

    width: 285px;
    height: 285px;

    padding-left: 24px;
    padding-right: 24px;

    display: flex;
    flex-direction: column;
    position: relative;

    &--card1 {
      z-index: 1;
      background-image: url("@/assets/img/section1/card-1.svg");
      background-repeat: no-repeat;
      background-size: 220px;
      background-position: right top;
      background-color: #AA6DE3;

      padding-bottom: 47px;

      justify-content: flex-end;

      h3 {
        color: white;
      }

      p {
        color: white;
      }
    }

    &--card2 {
      z-index: 2;
      background-color: #FEDF55;
      background-image: url("@/assets/img/section1/card-2.svg");
      background-repeat: no-repeat;
      background-size: 227px;
      background-position: right calc(100% + 2px);

      padding-top: 27px;

      margin-top: -155px;
      margin-right: 0;
      margin-left: auto;

      h3 {
        color: #393939;
        text-align: center;
      }

      p {
        color: #393939;
        text-align: center;
        max-width: 200px;

        margin-left: auto;
        margin-right: auto;
      }
    }

    &--card3 {
      z-index: 3;
      background-color: #FF8DBE;
      background-image: url("@/assets/img/section1/card-3.svg");
      background-repeat: no-repeat;
      background-size: 186px;
      background-position: left bottom;

      align-items: flex-end;

      padding-top: 28px;
      margin-left: 16%;
      margin-top: -165px;

      h3 {
        color: #393939;
        text-align: right;
        max-width: 180px;

        br {
          display: none;
        }
      }

      p {
        color: #393939;
        text-align: right;
        max-width: 148px;
      }
    }

    &-title {
      color: #FFF;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.96px;
      text-transform: uppercase;

    }

    &-text {
      color: #FFF;
      font-size: 16px;
      font-weight: 400;
      line-height: 132%;

      :deep(b) {
        font-weight: 800;

      }
    }
  }

  &__arrow {
    position: absolute;
    bottom: calc(15vh);

    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;

    width: 64px;
  }
}

@media screen and (max-width: 1280px) {
  .content {

    &__item {


      &--card1 {


        h3 {
        }

        p {
        }
      }

      &--card2 {

        margin-left: 255px;

        h3 {
        }

        p {
        }
      }

      &--card3 {
        margin-top: -110px;

        h3 {
        }

        p {
        }
      }

      &-title {

      }

      &-text {

        b {
        }
      }
    }

  }
}

@media screen and (max-width: 1180px) {
  .content {

    &__item {
      width: 235px;
      height: 235px;
      padding: 20px;

      &--card1 {

        background-size: 173px;

        h3 {
        }

        p {
        }
      }

      &--card2 {
        background-size: 179px;
        align-items: center;
        margin-left: 215px;
        margin-top: -134px;

        h3 {
        }

        p {
          max-width: 170px;

        }
      }

      &--card3 {
        background-size: 147px;

        margin-top: -81px;

        h3 {
        }

        p {
        }
      }

      &-title {
        font-size: 20px;

      }

      &-text {
        font-size: 14px;

        b {
        }
      }
    }

  }
}

@media screen and (max-width: 1020px) {
  .content {

    padding-top: 32px;

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__left {
      max-width: 1024px;
      margin-right: 0;
    }

    &__right {
      max-width: 1024px;
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-top: 70px;


    }

    &__message {
      justify-content: center;

      &-icon {
      }

      &-text {

      }

      &-accent {
      }
    }

    &__title {


      margin-top: 28px;

      text-align: center;
      font-size: 48px;
      line-height: 116%;
      letter-spacing: 1.92px;
      max-width: 580px;
      margin-left: auto;

      margin-right: auto;

      &-letter {
      }

      &-border {
      }
    }

    &__btn {
      width: 276px;
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
    }

    &__item {
      border-radius: 24px;

      width: 216px;
      height: 216px;

      padding-left: 20px;
      padding-right: 20px;

      margin: 10px;

      &--card1 {
        z-index: 1;
        order: 2;
        background-size: 167px auto;

        padding-bottom: 20px;
        padding-top: 20px;

        justify-content: flex-end;

        h3 {
        }

        p {
        }
      }

      &--card2 {
        background-size: 124px;
        order: 3;

        padding-top: 20px;

        h3 {
        }

        p {
        }
      }

      &--card3 {
        background-size: 124px;
        order: 1;

        h3 {
        }

        p {
          max-width: 120px;
        }
      }

      &-title {
        font-size: 18px;
        line-height: 132%;
        letter-spacing: 0.72px;
      }

      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 132%;

        b {
          font-weight: 800;

        }
      }
    }

    &__arrow {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .content {
    min-height: 1px;

    padding-top: 16px;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;

    &__left {
    }

    &__right {
      margin-top: 40px;
    }

    &__message {

      &-icon {
        width: 76px;
      }

      &-text {
        font-size: 14px;
        line-height: 124%;
        max-width: 213px;
      }

      &-accent {
        font-size: 14px;
      }
    }

    &__title {

      font-size: 28px;
      line-height: 120%; /* 33.6px */
      max-width: 297px;

      &-letter {
        font-size: 28px;
        font-weight: 600;


      }

      &-border {
        font-size: 28px;
        font-weight: 600;

      }
    }

    &__btn {
      width: 248px;
      margin-top: 24px;
    }

    &__item {
      //margin-left: 0;
      //margin-right: 0;


      &--card1 {

        h3 {
        }

        p {
        }
      }

      &--card2 {


        h3 {
        }

        p {
        }
      }

      &--card3 {

        h3 {
        }

        p {
        }
      }

      &-title {
      }

      &-text {

        b {
        }
      }
    }

    &__arrow {
    }
  }
}

@media screen and (max-width: 535px) {
  .content {

    &__left {
    }

    &__right {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    &__message {

      &-icon {
      }

      &-text {
      }

      &-accent {
      }
    }

    &__title {


      &-letter {

      }

      &-border {

      }
    }

    &__btn {
    }

    &__item {
      max-width: 296px;

      width: 100%;
      margin: 0 auto 16px auto;
      height: 160px;
      padding: 20px;

      &--card1 {

        background-size: 155px;
        background-position-y: -11px;

        h3 {
        }

        p {
          max-width: 176px;

        }
      }

      &--card2 {
        background-size: 172px;
        background-position: 7px 130%;

        margin-bottom: 0;

        h3 {
          text-align: right;
          margin-left: auto;
          margin-right: 0;
        }

        p {
          text-align: right;
          max-width: 120px;
          margin-left: auto;

          margin-right: 0;
        }
      }

      &--card3 {

        background-size: 123px;
        background-position-y: 0;

        h3 {
          max-width: 211px;

          br {
            display: block;
          }
        }

        p {
          max-width: 130px;
        }
      }

      &-title {
      }

      &-text {

        b {
        }
      }
    }

    &__arrow {
    }
  }
}
</style>