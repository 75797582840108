<script setup>
import {ref} from "vue";
import Section9Parallax from "@/components/svg-animation/section9-parallax.vue";
import DropArrow from "@/components/ui/drop-arrow.vue";

const isOpen1 = ref(false);
const isOpen2 = ref(false);
const isOpen3 = ref(false);
const isOpen4 = ref(false);
const isOpen5 = ref(false);
</script>

<template>
  <section class="section section9" id="section9">

    <div class="content">
      <div class="content__left">
        <h2 class="content__title js-scroll  fade-in-title">
          <span class="animation-title ">Часті запитання</span>
          <span class="animation-title delay-1">щодо вакцинації</span>
          <span class="animation-title delay-2">проти</span>
          <span class="animation-title delay-3">менінгокока <span
            class="content__title--border">[</span>Б<span class="content__title--border">]</span></span>
        </h2>

        <div class="content__parallax ">
          <section9-parallax/>
        </div>
      </div>

      <div class="content__right">
        <ul class="content__list js-scroll  fade-in-top">

          <li v-show="!isOpen1" :class="{active: isOpen1}" class="content__list-item">
            <p
              onclick="gtag('event', 'click_item_question', {'btn_name': 'Коли потрібно звернутися за порадою до лікаря?'})"
              @click="isOpen1 = !isOpen1" class="content__list-item--visible">Коли потрібно звернутися за порадою до
              лікаря?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Менінгокок [Б] найчастіше зустрічається у дітей до 1 року та
              молодих
              людей у віці 16-23
              років<sup>4</sup>.</p>
          </li>
          <li v-show="isOpen1" :class="{active: isOpen1}" class="content__list-item">
            <p @click="isOpen1 = !isOpen1" class="content__list-item--visible">Коли потрібно звернутися за порадою до
              лікаря?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Менінгокок [Б] найчастіше зустрічається у дітей до 1 року та
              молодих
              людей у віці 16-23
              років<sup>4</sup>.</p>
          </li>

          <li v-show="!isOpen2" :class="{active: isOpen2}" class="content__list-item">
            <p
              onclick="gtag('event', 'click_item_question', {'btn_name': 'Які фактори ризику збільшують імовірність інфікування менінгококом [Б]?'})"
              @click="isOpen2 = !isOpen2" class="content__list-item--visible">Які фактори ризику збільшують імовірність
              інфікування менінгококом
              [Б]?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Збудник передається в закритих колективах, на зразок
              студентських
              гуртожитків, військових
              частин, тощо<sup>4</sup>. Якщо в родині є студенти чи військові – це підвищує ризик інфікування інших
              членів
              сім’ї.
              Крім того, до 10% населення є носіями менінгококу, у яких хвороба не розвивається, але може передаватись
              оточуючим<sup>4</sup>.</p>
          </li>
          <li v-show="isOpen2" :class="{active: isOpen2}" class="content__list-item">
            <p
              @click="isOpen2 = !isOpen2" class="content__list-item--visible">Які фактори ризику збільшують імовірність
              інфікування менінгококом
              [Б]?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Збудник передається в закритих колективах, на зразок
              студентських
              гуртожитків, військових
              частин, тощо<sup>4</sup>. Якщо в родині є студенти чи військові – це підвищує ризик інфікування інших
              членів
              сім’ї.
              Крім того, до 10% населення є носіями менінгококу, у яких хвороба не розвивається, але може передаватись
              оточуючим<sup>4</sup>.</p>
          </li>

          <li v-show="!isOpen3" :class="{active: isOpen3}" class="content__list-item">
            <p
              onclick="gtag('event', 'click_item_question', {'btn_name': 'Чи можна вакцинувати дитину проти менінгокока [Б] одночасно з іншими щепленнями?'})"
              @click="isOpen3 = !isOpen3" class="content__list-item--visible">Чи можна вакцинувати дитину проти
              менінгокока [Б] одночасно з іншими
              щепленнями?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Так, вакцинація проти менінгококу [Б] сумісна з більшістю
              планових
              щеплень<sup>5</sup>.
              Однак
              краще
              довірте рішення про одночасне застосування з іншими вакцинами Вашому педіатру чи сімейному лікарю.</p>
          </li>
          <li v-show="isOpen3" :class="{active: isOpen3}" class="content__list-item">
            <p
              @click="isOpen3 = !isOpen3" class="content__list-item--visible">Чи можна вакцинувати дитину проти
              менінгокока [Б] одночасно з іншими
              щепленнями?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Так, вакцинація проти менінгококу [Б] сумісна з більшістю
              планових
              щеплень<sup>5</sup>.
              Однак
              краще
              довірте рішення про одночасне застосування з іншими вакцинами Вашому педіатру чи сімейному лікарю.</p>
          </li>

          <li v-show="!isOpen4" :class="{active: isOpen4}" class="content__list-item">
            <p
              onclick="gtag('event', 'click_item_question', {'btn_name': 'Чи потрібно робити щеплення проти менінгококу [Б], якщо раніше вже проводилась інша вакцинація проти менінгококу?'})"
              @click="isOpen4 = !isOpen4" class="content__list-item--visible">Чи потрібно робити щеплення проти
              менінгококу [Б], якщо раніше вже
              проводилась інша вакцинація проти менінгококу?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Так, оскільки донедавна в Україні була доступна лише
              вакцинація
              проти менінгококів
              серогруп
              А, С, Y та W. Вона не забезпечує імунітет проти найбільш поширеної в Європі серогрупи [Б] <sup>1</sup>.
            </p>
          </li>
          <li v-show="isOpen4" :class="{active: isOpen4}" class="content__list-item">
            <p
              @click="isOpen4 = !isOpen4" class="content__list-item--visible">Чи потрібно робити щеплення проти
              менінгококу [Б], якщо раніше вже
              проводилась інша вакцинація проти менінгококу?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Так, оскільки донедавна в Україні була доступна лише
              вакцинація
              проти менінгококів
              серогруп
              А, С, Y та W. Вона не забезпечує імунітет проти найбільш поширеної в Європі серогрупи [Б] <sup>1</sup>.
            </p>
          </li>

          <li v-show="!isOpen5" :class="{active: isOpen5}" class="content__list-item">
            <p
              onclick="gtag('event', 'click_item_question', {'btn_name': 'Де можна вакцинуватись проти менінгокока [Б]?'})"
              @click="isOpen5 = !isOpen5" class="content__list-item--visible">Де можна вакцинуватись проти менінгокока&nbsp;[Б]?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Зверніться до свого сімейного лікаря або педіатра. Він проведе
              огляд
              на предмет
              протипоказань
              до вакцинації, відповість на Ваші питання та зможе призначити щеплення.</p>
          </li>
          <li v-show="isOpen5" :class="{active: isOpen5}" class="content__list-item">
            <p
              @click="isOpen5 = !isOpen5" class="content__list-item--visible">Де можна вакцинуватись проти менінгокока&nbsp;[Б]?
              <DropArrow/>
            </p>
            <p class="content__list-item--hidden hidden">Зверніться до свого сімейного лікаря або педіатра. Він проведе
              огляд
              на предмет
              протипоказань
              до вакцинації, відповість на Ваші питання та зможе призначити щеплення.</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.content {
  padding-top: 20px;
  padding-bottom: 100px;
  justify-content: space-between;

  &__left {
    max-width: 590px;
    width: 100%;
    margin-right: 10px;
  }

  &__right {
    max-width: 590px;
    width: 100%;
    margin-left: 10px;
  }

  &__title {
    color: #393939;
    font-size: 52px;
    font-weight: 600;
    line-height: 116%;
    letter-spacing: 2.08px;
    text-transform: uppercase;


    &--border {
      font-weight: 100;
    }
  }

  &__parallax {
    max-width: 429px;
    display: flex;
    margin: 40px auto 0 auto;
    width: 100%;
  }

  &__list {
    &-item {
      margin-bottom: 20px;
      padding: 20px 40px;

      border-radius: 24px;
      background: #F7F3E7;


      &.active {
        .hidden {
          display: block !important;
        }

        svg {
          transform: rotate(180deg);
        }
      }

      &--visible {
        user-select: none;
        color: #393939;
        cursor: pointer;

        font-size: 20px;
        font-weight: 600;
        line-height: 132%;
        letter-spacing: 0.4px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        svg {
          margin-left: 40px;
        }


        @media screen and (min-width: 1024px) {
          &:hover {
            :deep(svg) {
              rect {
                fill: #8C47CC;
              }
            }
          }
        }
      }

      &--hidden {
        display: none;
        margin-top: 20px;
        color: #393939;

        font-size: 16px;
        font-weight: 400;
        line-height: 132%;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .content {
    padding-top: 0;
    padding-bottom: 80px;
    flex-direction: column;


    &__left {
      max-width: 100%;
      margin-right: 0;
      display: flex;
      position: relative;
      align-items: center;
    }

    &__right {
      max-width: 100%;
      margin-left: 0;
    }

    &__title {
      max-width: 559px;
      width: 100%;
      font-size: 48px;
      line-height: 116%;
      letter-spacing: 1.92px;

      &--border {
      }
    }

    &__parallax {
      //position: absolute;
      max-width: 285px;
      //right: -16%;
      //top: 0;
      margin: 0 -13% 0 auto;
    }

    &__list {
      margin-top: 10px;

      &-item {

        &.active {
          .hidden {
          }

          svg {
          }
        }

        &--visible {

          svg {
          }


          @media screen and (min-width: 1024px) {
            &:hover {
              :deep(svg) {
                rect {
                }
              }
            }
          }
        }

        &--hidden {
        }

        &:last-of-type {
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .content {
    padding-top: 0;
    padding-bottom: 32px;

    padding-left: 8px;
    padding-right: 8px;

    &__left {

      flex-direction: column;
      align-items: flex-start;
    }

    &__right {

    }

    &__title {

      font-size: 28px;
      line-height: 120%;
      letter-spacing: 1.12px;
      max-width: 310px;

      &--border {
        font-size: 28px;
      }
    }

    &__parallax {
      max-width: 285px;
      margin: 16px auto 0 auto;
    }

    &__list {
      margin-top: 20px;

      &-item {
        padding: 16px 20px;
        margin-bottom: 16px;

        &.active {
          .hidden {

          }

          svg {
          }
        }

        &--visible {
          font-size: 16px;

          svg {
            min-width: 24px;
            max-width: 24px;
          }


          @media screen and (min-width: 1024px) {
            &:hover {
              :deep(svg) {
                rect {
                }
              }
            }
          }
        }

        &--hidden {
          font-size: 14px;
          margin-top: 16px;
        }

        &:last-of-type {
        }
      }
    }
  }
}
</style>