<script setup>
</script>

<template>
  <section class="section section10" id="section10">

    <div class="content">
      <h2 class="content__title js-scroll  fade-in-title">
        <span class="animation-title ">Не пропускайте літеру <span
          class="content__title--border">[</span>Б<span
          class="content__title--border">]</span></span>
        <span class="animation-title delay-1">в абетці вакцинації</span>
      </h2>
      <div class="content__alert js-scroll fade-in-top ">
        <img class="content__alert-icon" src="@/assets/img/section10/icon-1.svg" alt="">
        <p class="content__alert-text">Зверніться до свого педіатра або сімейного лікаря та дізнайтеся більше про
          щеплення проти менінгококових
          захворювань, викликаних
          серогрупами В [Б], А, С, W та Y</p>

      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.content {

  padding-top: 20px;
  padding-bottom: 100px;

  flex-direction: column;
  align-items: center;

  &__title {
    color: #393939;

    font-size: 52px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 2.08px;
    text-transform: uppercase;

    text-align: center;
    max-width: 828px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &--border {
      font-weight: 100;
      line-height: 116%;
    }
  }

  &__alert {
    margin-top: 44px;
    padding: 32px 40px 44px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 24px;
    background: #FEDF55;
    max-width: 793px;
    width: 100%;

    &-icon {
      width: 80px;
      height: auto;
      display: block;
    }

    &-text {
      max-width: 606px;
      width: 100%;
      margin-top: 20px;

      color: #393939;
      text-align: center;

      font-size: 24px;
      font-weight: 600;
      line-height: 124%;

    }
  }

  &__btn {
    margin-top: 28px;
    max-width: 276px;
  }
}

@media screen and (max-width: 1020px) {
  .content {

    padding-top: 0;
    padding-bottom: 0;

    &__title {
      max-width: 588px;

      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 116%;
      letter-spacing: 1.92px;


      &--border {
      }
    }

    &__alert {

      &-icon {
      }

      &-text {
      }
    }

    &__btn {
    }
  }
}

@media screen and (max-width: 600px) {
  .content {

    padding-top: 0;
    padding-bottom: 0;

    &__title {
      padding-left: 8px;
      padding-right: 8px;

      font-size: 28px;
      line-height: 120%;
      letter-spacing: 1.12px;

      max-width: 290px;

      &--border {
        font-size: 28px;
      }
    }

    &__alert {
      margin-top: 24px;
      padding: 26px 0 32px 0;
      position: relative;

      &:before {
        z-index: 1;
        content: '';
        width: calc(100% + 48px);
        height: 100%;

        position: absolute;
        top: 0;
        left: -24px;
        right: -24px;

        background: #FEDF55;
        border-radius: 24px;
      }

      &-icon {
        position: relative;
        z-index: 2;
        width: 60px;
      }

      &-text {
        margin-top: 4px;
        position: relative;
        z-index: 2;
        font-size: 18px;
        line-height: 124%;
        letter-spacing: 0.36px;
      }
    }

    &__btn {
      position: relative;
      z-index: 2;
      max-width: 312px;
    }
  }
}

</style>