<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.76 119.76">

    <path class="cls-1"
          d="M65.74,.62c6.42-1.68,13.25,.11,18.03,4.72l1.13,1.09c2.29,2.21,5.1,3.81,8.17,4.65l1.51,.42c6.4,1.76,11.42,6.72,13.26,13.1l.43,1.51c.88,3.06,2.51,5.85,4.75,8.12l1.1,1.12c4.66,4.72,6.53,11.53,4.93,17.97l-.38,1.52c-.77,3.09-.75,6.32,.06,9.4l.4,1.52c1.68,6.42-.11,13.25-4.71,18.03l-1.09,1.13c-2.21,2.29-3.81,5.1-4.65,8.17l-.42,1.51c-1.76,6.4-6.72,11.42-13.1,13.26l-1.51,.43c-3.06,.88-5.85,2.51-8.11,4.75l-1.12,1.1c-4.72,4.66-11.53,6.53-17.97,4.93l-1.52-.38c-3.09-.77-6.32-.75-9.4,.06l-1.52,.4c-6.42,1.68-13.25-.11-18.03-4.72l-1.13-1.09c-2.29-2.21-5.1-3.81-8.17-4.65l-1.51-.42c-6.4-1.76-11.42-6.72-13.26-13.1l-.43-1.51c-.88-3.06-2.51-5.85-4.75-8.11l-1.1-1.12C.96,79.71-.91,72.9,.69,66.46l.38-1.52c.77-3.09,.75-6.32-.06-9.4l-.4-1.52c-1.68-6.42,.11-13.25,4.72-18.03l1.09-1.13c2.21-2.29,3.81-5.1,4.65-8.17l.42-1.51c1.76-6.4,6.72-11.42,13.1-13.26l1.51-.43c3.06-.88,5.85-2.51,8.12-4.75l1.12-1.1C40.05,.96,46.86-.91,53.3,.69l1.52,.38c3.09,.77,6.32,.75,9.4-.06l1.52-.4Z"/>
    <g>
      <path class="cls-3"
            d="M61.39,32.05c3.14-.94,6.54-.18,8.98,2.01,1.35,1.21,2.98,2.06,4.74,2.48,3.19,.75,5.75,3.11,6.77,6.23,.56,1.72,1.55,3.27,2.86,4.51,2.38,2.25,3.43,5.57,2.75,8.78-.37,1.77-.3,3.61,.22,5.34,.94,3.14,.18,6.54-2.01,8.98-1.21,1.35-2.06,2.98-2.48,4.74-.75,3.19-3.11,5.75-6.23,6.77-1.72,.56-3.27,1.55-4.51,2.86-2.25,2.38-5.57,3.43-8.78,2.75-1.77-.37-3.61-.3-5.34,.22-3.14,.94-6.54,.18-8.98-2.01-1.35-1.21-2.98-2.06-4.74-2.48-3.19-.75-5.75-3.11-6.77-6.23-.56-1.72-1.55-3.27-2.86-4.51-2.38-2.25-3.43-5.57-2.75-8.78,.37-1.77,.3-3.61-.22-5.34-.94-3.14-.18-6.54,2.01-8.98,1.21-1.35,2.06-2.98,2.48-4.74,.75-3.19,3.11-5.75,6.23-6.77,1.72-.56,3.27-1.55,4.51-2.86,2.25-2.38,5.57-3.43,8.78-2.75,1.77,.37,3.61,.3,5.34-.22Z"/>
      <path class="cls-2"
            d="M55.7,88.81c-2.47,0-4.91-.9-6.78-2.58-1.25-1.13-2.78-1.93-4.42-2.31-3.41-.81-6.2-3.37-7.28-6.7-.52-1.6-1.45-3.06-2.68-4.22-2.55-2.4-3.68-6.02-2.96-9.44,.35-1.65,.27-3.38-.21-4.99-1-3.35-.18-7.05,2.16-9.66,1.12-1.25,1.92-2.78,2.31-4.42,.81-3.41,3.37-6.2,6.7-7.28,1.6-.52,3.06-1.45,4.21-2.68,2.4-2.55,6.02-3.68,9.44-2.96,1.65,.35,3.37,.27,4.99-.21,3.35-1,7.05-.18,9.66,2.16,1.26,1.13,2.79,1.93,4.42,2.31,3.41,.81,6.2,3.37,7.29,6.7,.52,1.6,1.45,3.06,2.68,4.22,2.55,2.4,3.68,6.02,2.96,9.44-.35,1.65-.28,3.37,.21,4.99,1,3.35,.18,7.05-2.16,9.66-1.12,1.25-1.92,2.78-2.31,4.42-.81,3.4-3.37,6.2-6.7,7.28-1.6,.52-3.06,1.45-4.22,2.68-2.4,2.55-6.01,3.68-9.44,2.96-1.65-.35-3.37-.28-4.99,.21-.94,.28-1.91,.42-2.88,.42Zm-1.56-56.03c-2.37,0-4.69,.97-6.34,2.72-1.32,1.4-2.98,2.46-4.81,3.05-2.86,.93-5.06,3.33-5.75,6.26-.44,1.87-1.36,3.62-2.64,5.05-2.01,2.24-2.72,5.42-1.85,8.29,.55,1.84,.64,3.81,.24,5.69-.62,2.94,.35,6.05,2.54,8.11,1.4,1.32,2.46,2.98,3.05,4.81,.93,2.86,3.33,5.06,6.26,5.75,1.87,.44,3.62,1.36,5.05,2.64,2.24,2.01,5.41,2.72,8.29,1.85,1.84-.55,3.81-.63,5.69-.24,2.94,.62,6.05-.35,8.11-2.54,1.32-1.4,2.98-2.46,4.81-3.05,2.86-.93,5.06-3.33,5.75-6.26,.44-1.87,1.36-3.62,2.64-5.05,2.01-2.24,2.72-5.41,1.85-8.29-.55-1.84-.63-3.81-.24-5.69,.62-2.94-.35-6.05-2.54-8.11-1.4-1.32-2.46-2.98-3.05-4.81-.93-2.86-3.33-5.06-6.26-5.75-1.87-.44-3.62-1.36-5.05-2.64-2.24-2.01-5.41-2.72-8.29-1.85h0c-1.84,.55-3.81,.63-5.69,.24-.58-.12-1.18-.18-1.77-.18Z"/>
    </g>
    <path class="cls-2"
          d="M63.07,56.58c-.56-.12-1-.55-1.11-1.11l-1.85-9.05c-.06-.28-.29-.38-.47-.38s-.41,.1-.47,.38l-1.85,9.05c-.11,.56-.55,1-1.11,1.11l-9.05,1.85c-.28,.06-.38,.29-.38,.47s.1,.41,.38,.47l9.05,1.85c.56,.11,1,.55,1.11,1.11l1.85,9.05c.06,.28,.29,.38,.47,.38s.41-.1,.47-.38l1.85-9.05c.12-.56,.55-1,1.11-1.11l9.05-1.85c.28-.06,.38-.29,.38-.47s-.1-.41-.38-.47l-9.05-1.85Z"/>
    <g class="text">
      <polygon class="cls-2"
               points="19.39 30.96 18.41 32.71 22.85 35.16 20.12 40.05 15.68 37.6 14.7 39.35 25.15 45.13 26.13 43.38 21.43 40.78 24.17 35.89 28.86 38.49 29.84 36.74 19.39 30.96"/>
      <path class="cls-2"
            d="M37.36,28.65c.26-.86,.26-1.74,0-2.68s-.8-1.87-1.58-2.75l-.37-.42c-.78-.89-1.63-1.53-2.54-1.92-.65-.28-1.3-.42-1.94-.42-.25,0-.49,.02-.73,.06-.88,.15-1.7,.55-2.45,1.2-.75,.65-1.26,1.41-1.52,2.26-.26,.85-.26,1.76,0,2.7,.27,.95,.8,1.88,1.58,2.77l.41,.46c.76,.86,1.61,1.49,2.51,1.88,.89,.38,1.79,.5,2.67,.34,.89-.15,1.71-.55,2.45-1.2,.75-.66,1.26-1.42,1.52-2.27Zm-2.54,1.11c-.53,.47-1.12,.7-1.75,.7-.24,0-.49-.03-.74-.1-.88-.24-1.76-.87-2.63-1.86l-.42-.47c-.88-1.02-1.37-1.97-1.47-2.84-.1-.91,.22-1.69,.94-2.32,.73-.63,1.55-.84,2.45-.62,.87,.22,1.73,.83,2.65,1.87l.3,.34h0s.11,.12,.11,.12c.9,1.04,1.39,1.97,1.49,2.85,.11,.91-.21,1.7-.93,2.33Z"/>
      <path class="cls-2"
            d="M49.26,22.14c.44-.8,.45-1.69,.03-2.73-.24-.6-.6-1.04-1.07-1.31-.47-.28-1.02-.38-1.6-.29l-.85,.12,.63-.58c.4-.37,.64-.79,.75-1.3,.11-.49,.06-.99-.15-1.52-.39-.96-1-1.53-1.88-1.76-.89-.23-2.02-.07-3.37,.48l-3.82,1.55,4.5,11.07,4.26-1.73c1.25-.52,2.11-1.19,2.56-2Zm-8.85-6.68l1.92-.78c.72-.29,1.3-.38,1.76-.26,.49,.13,.86,.5,1.1,1.1,.22,.55,.21,1.04-.04,1.48-.23,.41-.68,.76-1.33,1.04l-2.02,.82-1.39-3.41Zm6.94,6.22c-.24,.45-.68,.81-1.29,1.06l-2.3,.93-1.47-3.62,2.44-.99c.62-.24,1.16-.27,1.61-.09,.45,.19,.8,.58,1.05,1.18,.23,.55,.21,1.06-.03,1.53Z"/>
      <polygon class="cls-2"
               points="61.38 21.33 63.4 21.22 62.75 9.32 60.61 9.44 55.64 18.79 55.15 9.75 53.14 9.86 53.79 21.76 55.93 21.64 60.89 12.31 61.38 21.33"/>
      <polygon class="cls-2"
               points="77.81 12.42 76.33 17.27 70.97 15.66 72.44 10.81 70.52 10.24 67.04 21.65 68.97 22.23 70.53 17.1 75.9 18.71 74.33 23.84 76.26 24.42 79.73 13 77.81 12.42"/>
      <polygon class="cls-2"
               points="94.01 21.85 92.02 20.41 86.59 22.67 85.28 21.72 85.45 21.49 88.23 17.67 86.6 16.5 79.57 26.15 81.19 27.32 84.21 23.19 85.78 24.32 85.36 30.34 87.3 31.75 87.72 24.26 94.01 21.85"/>
      <path class="cls-2"
            d="M100.94,29.75l-12.35,3.25,1.22,1.73,2.61-.78,.12,.17,2.72,3.85-1.62,2.19,1.22,1.73,7.22-10.52-1.15-1.62Zm-4.72,6.93l-2.06-2.93,.13-.06h0l-.13,.05-.09-.29,5.83-1.76-3.68,4.99Z"/>
      <path class="cls-2"
            d="M110.37,65.74l.3-7.63-5.24-.54c-1.26-.15-2.27-.38-3.11-.69-.85-.32-1.51-.77-1.95-1.34l-.06-.08,.03-.76-4.58-.05-.08,1.87,3.07,.12-.32,8.1-3.08-.11-.07,1.82,4.56,.35,.06-1.45h.28s10.17,.39,10.17,.39Zm-10.38-8.14l.67,.47c1.09,.76,2.74,1.28,4.88,1.52l3.55,.36-.14,3.72h-.29s-8.64-.33-8.64-.33v-.29s.2-4.71,.2-4.71l-.22-.74Z"/>
      <path class="cls-2"
            d="M94.53,76.52l-.79,1.85,11,4.63,3.06-7.2-4.96-2.31c-1.58-.75-2.61-1.38-3.14-1.92-.58-.59-.77-1.25-.56-1.99l.05-.17-1.41-.59-.15,.35c-.35,.84-.46,1.58-.33,2.18,.13,.6,.52,1.19,1.15,1.76,.65,.58,1.64,1.2,2.96,1.84h.02s4.22,2.02,4.22,2.02l-.1,.25-1.42,3.33-.26-.11-9.35-3.94Z"/>
      <path class="cls-2"
            d="M87.78,85.79l-1.33,1.51,8.95,7.88,2.75-3.1c.89-1.03,1.36-2.04,1.39-3.01,.02-.96-.38-1.8-1.24-2.55-.52-.46-1.08-.75-1.65-.86-.57-.11-1.19-.05-1.83,.18l-.26,.09-2.16-5.35-1.43,1.61,1.88,4.67-1.7,1.92-.21-.19-3.16-2.79Zm5.83,2.29c.49-.53,1-.83,1.51-.88,.46-.05,.94,.11,1.42,.47h0s.28,.22,.28,.22c.55,.49,.84,1.01,.86,1.56,.02,.54-.22,1.11-.72,1.67l-1.37,1.55-3.4-2.99,1.42-1.6Z"/>
      <path class="cls-2"
            d="M74.69,94.4c-1.26,.52-2.12,1.24-2.6,2.19-.48,.93-.51,1.89-.09,2.94,.09,.22,.48,.95,.87,1.31,.36,.34,.73,.55,1.24,.71,1,.32,2.16,.21,3.46-.32l2.13-.87,1.13,2.77-5.19,2.1,.57,1.4,7.07-2.87-4.49-11.04-4.11,1.67Zm2.22,5.48c-.39,.15-.77,.23-1.12,.23-.25,0-.48-.04-.71-.12-.55-.19-.95-.59-1.2-1.19v-.03c-.26-.63-.25-1.23,0-1.77,.26-.54,.73-.96,1.41-1.23h.03s2.14-.88,2.14-.88l1.67,4.09-2.23,.91Z"/>
      <path class="cls-2"
            d="M66.33,96.83l-.7,2.62-4.94,.26-1.01-2.46-2.13,.14,5.21,11.61,1.99-.13,3.7-12.18-2.13,.14Zm-2.7,10.14l-2.33-5.72,3.92-.25-1.59,5.97Z"/>
      <polygon class="cls-2"
               points="50.37 106.46 52.81 96.34 50.86 95.88 48.41 106 44.67 105.11 44.32 106.58 53.69 108.81 54.05 107.34 50.37 106.46"/>
      <path class="cls-2"
            d="M40.54,91.4c-1.16-.69-2.27-.96-3.31-.78-1.03,.17-1.85,.74-2.43,1.69-.58,.95-.71,1.92-.38,2.89,.33,.99,1.11,1.86,2.3,2.58l1.93,1.18-2.33,3.85,1.73,1.05,6.26-10.17-3.77-2.29Zm-3.11,5.04c-.62-.39-1.01-.86-1.17-1.4-.17-.55-.09-1.11,.25-1.67,.36-.59,.85-.95,1.45-1.07,.59-.12,1.21,.02,1.83,.4l2,1.21-2.32,3.77-2.05-1.25Z"/>
      <polygon class="cls-2"
               points="29.2 88.79 27.95 87.3 29.77 81.56 28.23 79.74 26.04 86.92 19.36 87.77 20.95 89.64 26.76 88.73 27.81 89.97 27.59 90.15 23.98 93.21 25.28 94.73 34.41 87.01 33.12 85.49 29.2 88.79"/>
      <polygon class="cls-2" points="15.37 80.35 16.25 82.15 27 76.9 26.12 75.1 15.37 80.35"/>
      <path class="cls-2"
            d="M11.97,66.56c-.37,.82-.41,1.96-.11,3.38l.87,4,11.68-2.53-.97-4.47c-.29-1.31-.8-2.26-1.51-2.84-.69-.56-1.59-.72-2.68-.49-.62,.13-1.11,.41-1.48,.83-.35,.41-.54,.92-.56,1.51l-.03,.9-.49-.76c-.3-.45-.67-.77-1.15-.96-.29-.12-.59-.17-.9-.17-.2,0-.4,.02-.62,.07-1.02,.22-1.69,.72-2.06,1.53Zm6.33-.15c.26-.42,.72-.7,1.36-.83,.59-.13,1.1-.03,1.52,.3,.41,.31,.69,.8,.83,1.46l.53,2.44-3.87,.84-.56-2.58c-.13-.66-.07-1.2,.19-1.61Zm-4.89,1.43c.22-.46,.66-.76,1.29-.9,.58-.13,1.08-.03,1.47,.29,.37,.3,.64,.81,.81,1.5l.46,2.12-.25,.08-3.4,.74-.44-2.04c-.16-.77-.14-1.35,.06-1.78Z"/>
    </g>
  </svg>


</template>

<style scoped lang="scss">
svg {
  width: 100%;
  height: auto;
  display: block;

  .text {
    will-change: rotate;
    transform-origin: center;
    animation: rotate 20s linear infinite;
  }
}

.cls-1 {
  fill: #ff8dbe;
}

.cls-2 {
  fill: #393939;
}

.cls-3 {
  fill: #fedf55;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>