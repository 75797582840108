import { createStore } from 'vuex'

export default createStore({
  state: {
    activeLink: null
  },
  getters: {
    activeLink: state => state.activeLink
  },
  mutations: {
    SET_ACTIVE_LINK: (state, newActiveLink) => {
      state.activeLink = newActiveLink;
    },
  },
  actions: {  setActiveLink({ commit }, link) {
      commit('SET_ACTIVE_LINK', link);
    }

  },
  modules: {
  }
})
