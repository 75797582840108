<script setup>

</script>

<template>
  <section class="section section7" id="section7">

    <div class="content">
      <h2 class="content__title js-scroll  fade-in-title">
        <span class="animation-title ">Трошки</span><br>
        <span class="animation-title delay-1">епідеміології</span>
      </h2>

      <div class="content__row">
        <div class="js-scroll fade-in-top delay-1-mob content__item content__item--item1">
          <h4 class="content__item-title content__item-title--color1">Безсимптомні <br>носії</h4>
          <p class="content__item-text content__item-text--color1"><b>Приблизно 5-10% людей є безсимптомними носіями
            менінгокока,</b> у яких збудник не
            проникає із слизової оболонки
            дихальних шляхів до кровообігу та не спричиняє менінгіт. Тим не менш, вони можуть заразити
            оточуючих<sup>4</sup>.</p>
          <img class="content__item-img" src="@/assets/img/section7/icon-1.svg" alt="">
        </div>
        <div class="js-scroll fade-in-top delay-2-mob content__item content__item--item2">
          <img class="content__item-img" src="@/assets/img/section7/icon-2.svg" alt="">
          <h4 class="content__item-title">Закриті <br>колективи</h4>
          <p class="content__item-text"><b>Менінгокок часто передається в закритих колективах,</b> наприклад,
            гуртожитках чи
            військових
            частинах<sup>4</sup>.
            Отже,
            вакцинації слід приділити особливу увагу, якщо хтось із батьків перебуває на військовій службі або в родині
            є
            студенти.</p>
        </div>
        <div class="js-scroll fade-in-top delay-3-mob content__item content__item--item3">
          <h4 class="content__item-title">Холодна <br>пора року</h4>
          <p class="content__item-text right">Оскільки <b>менінгокок поширюється повітряно-крапельним шляхом,</b>
            захворюваність
            зростає в холодну пору
            року<sup>4</sup>.</p>
          <img class="content__item-img" src="@/assets/img/section7/icon-3.svg" alt="">
        </div>
        <div class="js-scroll fade-in-top delay-4-mob content__item content__item--item4">
          <img class="content__item-img" src="@/assets/img/section7/icon-4.svg" alt="">
          <h4 class="content__item-title">Циклічний <br>характер</h4>
          <p class="content__item-text">Захворюваність на менінгококову інфекцію має циклічний характер та <b>досягає
            піку
            раз на 7-10
            років<sup>4</sup>.</b></p>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped lang="scss">
.content {
  padding-top: 20px;
  padding-bottom: 100px;

  flex-direction: column;

  &__title {
    color: #393939;

    font-size: 52px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 2.08px;
    text-transform: uppercase;

  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
  }

  &__item {
    max-width: calc((100% / 4) - 10px);
    width: 100%;
    border-radius: 24px;

    padding: 0 25px;
    display: flex;
    flex-direction: column;

    &--item1 {
      background: #AA6DE3;
      padding-top: 32px;
      padding-bottom: 28px;

      img {
        margin-top: auto;
        margin-bottom: 0;
      }
    }

    &--item2 {
      background: #FEDF55;

      padding-bottom: 24px;

      h4 {
        margin-top: -25px;
      }

      img {
        margin-right: -24px;
      }
    }

    &--item3 {
      background: #FF8DBE;

      padding-top: 28px;
      padding-bottom: 24px;

      align-items: flex-end;
      img {
        margin-top: auto;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: auto;
        max-width: 209px;
      }

      h4 {
        text-align: right;
      }

      p {
        text-align: right;
      }
    }

    &--item4 {
      background: #E5DCC0;

      padding-top: 22px;
      padding-bottom: 28px;

      h4 {
        margin-top: 14px;
      }
    }

    &-title {
      color: #393939;

      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.96px;
      text-transform: uppercase;


      &--color1 {
        color: white;
      }
    }

    &-text {
      color: #393939;

      font-size: 16px;
      font-weight: 400;
      line-height: 132%;
      margin-top: 10px;
      max-width: 236px;

      b {
        font-weight: 800;

      }

      &--color1 {
        color: white;
      }
    }

    &-img {
      display: block;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1240px) {
  .content {

    &__item {

      &--item1 {
        //padding-bottom: 12px;

        img {
          margin-top: 10px;

          //max-width: 159px;
          //margin-left: auto;
          //margin-right: auto;
          //margin-top: auto;
          //margin-bottom: 0;
        }
      }

      &--item2 {

        h4 {
          //margin-top: -10px;


        }

        img {
          //max-width: 153px;
          margin-left: auto;
          margin-right: -24px;
        }
      }

      &--item3 {

        //padding-top: 32px;

        img {
          //max-width: 139px;
        }

        h4 {

        }

        p {
          //max-width: 252px;
          //margin-right: 0;
          //margin-left: auto;
          //text-align: right;
        }
      }

      &--item4 {
        //background: #E5DCC0;
        //
        //padding-top: 22px;
        //padding-bottom: 28px;

        h4 {

        }

        img {
          //max-width: 163px;
          //margin-left: auto;
          //margin-right: 0;
        }
      }

      &-title {
        font-size: 18px;
        letter-spacing: 0.72px;
      }

      &-text {
        //font-size: 14px;
        //max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .content {
    padding-top: 0;
    padding-bottom: 80px;

    flex-direction: column;

    &__title {
      //color: #393939;
      //
      //font-size: 52px;
      //font-weight: 600;
      //line-height: 120%;
      //letter-spacing: 2.08px;
      //text-transform: uppercase;

      font-size: 48px;
      font-weight: 600;
      line-height: 116%;
      letter-spacing: 1.92px;


    }

    &__row {
      //display: flex;
      //justify-content: space-between;
      margin-top: 44px;
      flex-flow: row wrap;
    }

    &__item {
      max-width: calc((100% / 2) - 10px);
      margin-bottom: 20px;

      &--item1 {
        padding-bottom: 12px;


        img {
          max-width: 159px;
          margin-left: auto;
          margin-right: auto;
          //margin-top: auto;
          //margin-bottom: 0;
        }
      }

      &--item2 {

        h4 {
          margin-top: 5px;

          br {
            display: none;
          }
        }

        img {
          max-width: 153px;
          margin-left: auto;
          //margin-right: -24px;
        }
      }

      &--item3 {
        //background: #FF8DBE;

        padding-top: 32px;
        //padding-bottom: 24px;

        img {
          max-width: 139px;
        }

        h4 {


        }

        p {
          max-width: 252px;
          margin-right: 0;
          margin-left: auto;
          //text-align: right;
        }
      }

      &--item4 {
        //background: #E5DCC0;
        //
        //padding-top: 22px;
        //padding-bottom: 28px;

        h4 {

        }

        img {
          max-width: 163px;
          margin-left: auto;
          margin-right: 0;
        }
      }

      &-title {
        font-size: 18px;
        letter-spacing: 0.72px;

        br {
          display: none;
        }

        &--color1 {
        }
      }

      &-text {
        font-size: 14px;
        max-width: 100%;
        margin-top: 5px;


        b {
        }


        &--color1 {
        }
      }

      &-img {
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .content {

    padding-left: 8px;
    padding-right: 8px;

    padding-top: 0;
    padding-bottom: 32px;

    &__title {
      font-size: 28px;

      line-height: 120%;
      letter-spacing: 1.12px;
    }

    &__row {
      margin-top: 24px;
      flex-direction: column;
      max-width: 100%;


      align-items: center;
      justify-content: center;
    }

    &__item {
      max-width: 296px;


      &:last-of-type {
        margin-bottom: 0;
      }

      &--item1 {

        img {

        }
      }

      &--item2 {

        h4 {

          br {
          }
        }

        img {
        }
      }

      &--item3 {


        img {
        }

        h4 {


        }

        p {
        }
      }

      &--item4 {


        h4 {

        }

        img {

        }
      }

      &-title {


        br {
        }

        &--color1 {
        }
      }

      &-text {
        b {
        }


        &--color1 {
        }
      }

      &-img {
      }
    }
  }
}
</style>