<script>
import {ref} from 'vue';

export default {
  props: ['link'],
  setup(props) {
    const link = ref(props.link);

    return {
      link,
    }
  },
}
</script>

<template>
  <router-link :to="link">
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="bg"
            d="M27.0557 0.783095C30.3315 0.264265 33.6685 0.264265 36.9443 0.783095C40.22 1.30193 43.3937 2.33312 46.3488 3.83882C49.3039 5.34453 52.0036 7.30598 54.3488 9.65117C56.694 11.9964 58.6555 14.6961 60.1612 17.6512C61.6669 20.6063 62.6981 23.78 63.2169 27.0557C63.7357 30.3315 63.7357 33.6685 63.2169 36.9443C62.6981 40.22 61.6669 43.3937 60.1612 46.3488C58.6555 49.3039 56.694 52.0036 54.3488 54.3488C52.0036 56.694 49.3039 58.6555 46.3488 60.1612C43.3937 61.6669 40.22 62.6981 36.9443 63.2169C33.6685 63.7357 30.3315 63.7357 27.0557 63.2169C23.78 62.6981 20.6063 61.6669 17.6512 60.1612C14.6961 58.6555 11.9964 56.694 9.65117 54.3488C7.30598 52.0036 5.34453 49.3039 3.83882 46.3488C2.33312 43.3937 1.30193 40.22 0.783095 36.9443C0.264265 33.6685 0.264265 30.3315 0.783095 27.0557C1.30193 23.78 2.33312 20.6063 3.83882 17.6512C5.34453 14.6961 7.30597 11.9964 9.65116 9.65116C11.9964 7.30597 14.6961 5.34453 17.6512 3.83882C20.6063 2.33312 23.78 1.30193 27.0557 0.783095Z"
            fill="#E5DCC0"/>
      <path class="arrow" d="M41.4401 31.3531L31.5735 41.44L21.7068 31.3531M31.5735 21.7067V41.1575" stroke="#393939"
            stroke-width="1.5"
            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </router-link>

</template>

<style scoped lang="scss">
a {
  @media screen and (min-width: 1024px) {
    &:hover {
      .bg {
        fill: #FF8DBE;
      }
    }
  }
  :active{
    .bg {
      fill: #AA6DE3;
    }
  }
}

svg {
  width: 100%;
  height: auto;
  display: block;

  .bg {
    will-change: fill;
    transition: fill 200ms ease-in-out;
  }
}

</style>