<script setup>

</script>

<template>

  <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M66.1045 0.768319C72.526 -0.908572 79.3573 0.877853 84.1358 5.48359L85.265 6.572C87.5573 8.78138 90.3667 10.381 93.4366 11.2245L94.949 11.6401C101.349 13.3986 106.371 18.3613 108.207 24.7392L108.641 26.2464C109.521 29.306 111.154 32.096 113.391 34.3615L114.493 35.4775C119.156 40.2002 121.025 47.0095 119.425 53.4506L119.047 54.9728C118.28 58.0626 118.299 61.2955 119.104 64.3759L119.5 65.8934C121.177 72.3148 119.391 79.1461 114.785 83.9246L113.696 85.0538C111.487 87.3461 109.887 90.1556 109.044 93.2255L108.628 94.7378C106.87 101.137 101.907 106.16 95.5292 107.996L94.022 108.429C90.9624 109.31 88.1724 110.943 85.9069 113.18L84.7909 114.282C80.0682 118.945 73.2589 120.813 66.8178 119.214L65.2956 118.836C62.2058 118.069 58.9729 118.088 55.8925 118.893L54.375 119.289C47.9536 120.966 41.1223 119.179 36.3438 114.574L35.2146 113.485C32.9223 111.276 30.1128 109.676 27.0429 108.833L25.5306 108.417C19.131 106.659 14.1081 101.696 12.2727 95.318L11.839 93.8108C10.9585 90.7513 9.32522 87.9613 7.08839 85.6958L5.98645 84.5797C1.32351 79.8571 -0.545048 73.0478 1.05439 66.6066L1.43237 65.0845C2.19962 61.9946 2.18015 58.7617 1.37574 55.6814L0.979467 54.1639C-0.697423 47.7424 1.089 40.9111 5.69474 36.1326L6.78314 35.0034C8.99253 32.7111 10.5921 29.9017 11.4357 26.8318L11.8512 25.3194C13.6097 18.9199 18.5724 13.897 24.9504 12.0616L26.4576 11.6278C29.5171 10.7474 32.3071 9.11407 34.5726 6.87724L35.6887 5.7753C40.4113 1.11236 47.2206 -0.756197 53.6618 0.843243L55.1839 1.22122C58.2738 1.98847 61.5067 1.96901 64.587 1.16459L66.1045 0.768319Z"
      fill="#FF8DBE"/>

    <path
      d="M61.7492 32.1982C64.8863 31.2576 68.2869 32.0178 70.7249 34.2047C72.0722 35.4132 73.701 36.2647 75.4622 36.6814C78.6494 37.4354 81.2143 39.794 82.2322 42.9069C82.7948 44.6271 83.7796 46.1789 85.0965 47.4204C87.4796 49.667 88.5216 52.992 87.8467 56.1969C87.4738 57.9679 87.5508 59.8042 88.0705 61.5378C89.0111 64.675 88.2509 68.0756 86.0641 70.5136C84.8556 71.8609 84.0041 73.4896 83.5874 75.2509C82.8334 78.438 80.4747 81.0029 77.3618 82.0209C75.6416 82.5835 74.0898 83.5682 72.8483 84.8852C70.6018 87.2683 67.2767 88.3103 64.0718 87.6354C62.3008 87.2625 60.4646 87.3394 58.7309 87.8592C55.5938 88.7998 52.1932 88.0396 49.7551 85.8527C48.4078 84.6442 46.7791 83.7927 45.0179 83.376C41.8307 82.622 39.2658 80.2634 38.2478 77.1505C37.6853 75.4303 36.7005 73.8785 35.3836 72.637C33.0004 70.3904 31.9585 67.0654 32.6333 63.8605C33.0063 62.0895 32.9293 60.2532 32.4096 58.5196C31.469 55.3824 32.2292 51.9818 34.416 49.5438C35.6245 48.1965 36.476 46.5678 36.8927 44.8065C37.6467 41.6194 40.0053 39.0545 43.1182 38.0365C44.8385 37.4739 46.3902 36.4892 47.6317 35.1722C49.8783 32.7891 53.2034 31.7471 56.4082 32.422C58.1793 32.7949 60.0155 32.718 61.7492 32.1982Z"
      fill="#FEDF55" stroke="#393939" stroke-width="1.42857"/>
    <path
      d="M59.3002 46.5174C59.4559 45.7558 60.5442 45.7557 60.6999 46.5174L62.55 55.5694C62.6455 56.0367 63.0107 56.4019 63.478 56.4974L72.53 58.3476C73.2917 58.5033 73.2917 59.5915 72.53 59.7472L63.478 61.5974C63.0107 61.6929 62.6455 62.0581 62.55 62.5253L60.6999 71.5774C60.5442 72.339 59.4559 72.339 59.3002 71.5774L57.4501 62.5253C57.3545 62.0581 56.9894 61.6929 56.5221 61.5974L47.4701 59.7472C46.7084 59.5915 46.7084 58.5033 47.4701 58.3476L56.5221 56.4974C56.9894 56.4019 57.3545 56.0367 57.4501 55.5694L59.3002 46.5174Z"
      fill="#393939" stroke="#FEDF55" stroke-width="0.476191"/>
    <g class="text" clip-path="url(#clip0_286_554)">
      <path
        d="M97.8607 82.1759C96.9 81.7459 96.1388 81.2082 95.562 80.5598C94.9853 79.9113 94.6409 79.1849 94.5259 78.3953C94.4108 77.6058 94.5371 76.8097 94.896 76.0131C95.2459 75.2225 95.763 74.6043 96.4177 74.1528C97.0784 73.7102 97.8468 73.467 98.717 73.4531C99.5813 73.4302 100.479 73.6309 101.419 74.049L101.968 74.2891C102.922 74.7102 103.69 75.2568 104.263 75.9202C104.837 76.5835 105.19 77.304 105.297 78.0995C105.412 78.8891 105.294 79.6792 104.935 80.4758C104.577 81.2724 104.074 81.8935 103.414 82.3361C102.753 82.7787 101.994 83.0159 101.132 83.0238C100.262 83.0377 99.3495 82.8342 98.3947 82.4131L97.8547 82.167L97.8607 82.1759ZM99.127 80.7066C100.21 81.184 101.128 81.3577 101.888 81.198C102.654 81.0473 103.192 80.596 103.527 79.8412C103.85 79.1072 103.819 78.4039 103.433 77.7312C103.038 77.0645 102.324 76.4819 101.28 76.0045L100.71 75.7524C99.6362 75.2691 98.7149 75.1102 97.9403 75.267C97.1657 75.4237 96.6124 75.8721 96.2862 76.6209C95.9512 77.3757 95.9826 78.079 96.3716 78.7368C96.7605 79.3946 97.5069 79.9682 98.6019 80.4634L99.1151 80.6887L99.127 80.7066Z"
        fill="#393939"/>
      <path
        d="M89.055 90.753C88.2573 89.9292 87.8619 89.03 87.8537 88.0524C87.8455 87.0748 88.2487 86.1321 89.0364 85.2034C89.5893 84.5613 90.235 84.1157 90.9617 83.8876C91.6885 83.6594 92.4485 83.6547 93.2418 83.8734C94.0351 84.0922 94.811 84.5166 95.5694 85.1467L96.331 85.8007C97.0985 86.4636 97.6544 87.1776 97.999 87.9428C98.3435 88.708 98.4674 89.4916 98.344 90.2728C98.2206 91.0539 97.8766 91.776 97.3118 92.4389C96.5389 93.3319 95.6787 93.8435 94.7312 93.9738C93.7837 94.1041 92.8234 93.8291 91.8562 93.1578L93.0631 91.7542C93.7049 92.1909 94.2716 92.3803 94.7692 92.3315C95.2668 92.2827 95.7341 91.9955 96.1889 91.458C96.7121 90.8487 96.8983 90.1807 96.7298 89.4658C96.5612 88.7508 96.0559 88.0158 95.1898 87.2637L94.4671 86.6484C93.5862 85.8934 92.7864 85.472 92.059 85.3902C91.3316 85.3084 90.7063 85.5721 90.1771 86.1724C89.7045 86.7219 89.4613 87.247 89.4803 87.7387C89.4993 88.2305 89.7509 88.7604 90.2648 89.3345L89.058 90.7381L89.055 90.753Z"
        fill="#393939"/>
      <path
        d="M80.07 98.9461C79.5017 98.0592 79.1629 97.1867 79.0387 96.3256C78.9146 95.4645 79.032 94.6744 79.3671 93.9584C79.7112 93.2363 80.2463 92.6449 80.9904 92.1723C81.7255 91.7057 82.485 91.4685 83.2837 91.4637C84.0825 91.4589 84.8519 91.6807 85.5892 92.144C86.3264 92.6072 86.9628 93.2674 87.5221 94.1216L87.857 94.6215C88.4104 95.5054 88.7492 96.3779 88.8704 97.2539C88.9916 98.1299 88.8742 98.92 88.5391 99.6361C88.2039 100.352 87.6509 100.955 86.9158 101.422C86.1807 101.889 85.4123 102.132 84.6285 102.14C83.8357 102.153 83.0751 101.926 82.3379 101.462C81.6007 100.999 80.9583 100.33 80.396 99.452L80.067 98.961L80.07 98.9461ZM81.9494 98.4113C82.5895 99.4053 83.2676 100.05 83.9836 100.347C84.6995 100.643 85.4057 100.558 86.0962 100.122C86.7777 99.6908 87.137 99.0879 87.174 98.3129C87.211 97.5379 86.9348 96.6623 86.3215 95.689L85.9806 95.1802C85.3643 94.1831 84.6774 93.5439 83.9465 93.2445C83.2156 92.9452 82.5034 93.0213 81.8279 93.461C81.1285 93.9037 80.7692 94.5065 80.7322 95.2816C80.6893 96.0476 81.0013 96.9381 81.6533 97.95L81.9643 98.4142L81.9494 98.4113Z"
        fill="#393939"/>
      <path
        d="M70.889 106.364L75.6338 105.009L74.9187 102.513L72.8159 103.12C72.0683 103.336 71.368 103.392 70.736 103.298C70.1039 103.204 69.5728 102.952 69.1457 102.565C68.7186 102.179 68.4104 101.661 68.2391 101.038C67.9627 100.085 68.0651 99.2147 68.5581 98.4446C69.0421 97.6805 69.8426 97.1183 70.9685 96.7909L74.9001 95.6702L77.8415 105.949L71.3155 107.812L70.9039 106.367L70.889 106.364ZM74.4982 101.075L73.4977 97.5928L71.4038 98.1937C70.7962 98.3679 70.3737 98.664 70.1362 99.0818C69.8898 99.5056 69.8492 99.9855 70.0054 100.528C70.1586 101.046 70.4366 101.403 70.8513 101.617C71.2661 101.83 71.7669 101.844 72.3477 101.688L74.4982 101.075Z"
        fill="#393939"/>
      <path
        d="M56.4006 108.776L56.5014 98.0759L58.3493 98.0926L58.266 107.293L61.5446 107.322L61.7736 103.184C61.866 101.894 62.0247 100.895 62.2734 100.185C62.5221 99.4749 62.8727 98.9555 63.3398 98.6296C63.813 98.3126 64.4266 98.1473 65.1926 98.1515L65.6635 98.1593L65.649 99.6436L65.351 99.6622C64.9249 99.7019 64.5944 99.8457 64.3655 100.103C64.1277 100.365 63.9497 100.756 63.8228 101.281C63.6958 101.806 63.6052 102.578 63.542 103.604L63.2887 108.844L56.3917 108.782L56.4006 108.776Z"
        fill="#393939"/>
      <path
        d="M43.9979 105.728L42.2291 105.154L45.5619 94.9922L47.3307 95.5664L44.9628 102.805L51.7898 97.0286L53.5585 97.6027L50.2257 107.765L48.457 107.191L50.8338 99.9456L43.9979 105.728Z"
        fill="#393939"/>
      <path
        d="M42.8852 93.5646L36.7804 102.35L33.7683 100.268C32.7742 99.5755 32.1586 98.8497 31.9125 98.0962C31.6664 97.3427 31.8196 96.5674 32.3721 95.7704C32.6454 95.3674 33.0114 95.0834 33.4491 94.9065C33.8928 94.7386 34.3786 94.7107 34.9213 94.8257C34.5387 94.3705 34.3349 93.8733 34.3247 93.3368C34.3146 92.8004 34.4863 92.2843 34.8249 91.7858C35.3983 90.9619 36.1066 90.5132 36.935 90.4368C37.7783 90.3634 38.6791 90.6653 39.6672 91.3486L42.8762 93.5706L42.8852 93.5646ZM38.6089 96.4684L40.516 93.7161L38.8204 92.5367C38.3368 92.201 37.8835 92.065 37.4485 92.1107C37.0134 92.1564 36.6474 92.4016 36.3562 92.8166C35.7265 93.73 35.8654 94.5165 36.7849 95.194L38.6029 96.4595L38.6089 96.4684ZM37.8305 97.5849L36.332 96.5452C35.8543 96.2184 35.4129 96.0615 34.9957 96.0952C34.5785 96.1289 34.2362 96.3324 33.9629 96.7354C33.6599 97.1712 33.5655 97.5708 33.6795 97.9342C33.7936 98.2975 34.1164 98.6634 34.6328 99.0288L36.1165 100.066L37.8276 97.5998L37.8305 97.5849Z"
        fill="#393939"/>
      <path
        d="M22.2021 88.9892L21.0394 87.5433L29.3993 80.8688L30.562 82.3147L24.6119 87.0642L33.4942 85.9769L34.648 87.4288L26.2941 94.1122L25.1314 92.6663L31.0904 87.9108L22.1991 89.0041L22.2021 88.9892Z"
        fill="#393939"/>
      <path
        d="M15.5617 75.9888L14.8901 74.2527L24.8748 70.4089L25.5464 72.145L18.4383 74.8765L27.2374 76.5224L27.909 78.2585L17.9243 82.1023L17.2527 80.3662L24.3698 77.6288L15.5706 75.9828L15.5617 75.9888ZM12.9946 76.9067C13.5723 76.6878 14.1178 76.7104 14.6311 76.9745C15.1443 77.2385 15.5239 77.7086 15.7788 78.3786C16.0336 79.0486 16.0678 79.6596 15.8604 80.1995C15.653 80.7394 15.2694 81.1129 14.6977 81.3407L14.2239 80.093C14.5247 79.982 14.7299 79.8057 14.8307 79.57C14.9315 79.3343 14.922 79.0691 14.811 78.7683C14.7 78.4675 14.5237 78.2623 14.288 78.1615C14.0613 78.0547 13.796 78.0642 13.4863 78.1812L13.0066 76.9245L12.9946 76.9067Z"
        fill="#393939"/>
      <path
        d="M11.1762 48.8411L19.5536 48.4262L12.9772 43.1782L13.677 40.969L23.4336 44.122L22.8881 45.8229L19.6771 44.7858L15.4266 43.23L22.0236 48.5611L21.6326 49.7849L13.2036 50.2516L17.5467 51.4755L20.7578 52.5127L20.2123 54.2136L10.4763 51.0606L11.1762 48.8411Z"
        fill="#393939"/>
      <path
        d="M24.4832 34.0302L22.1572 37.5669L24.7816 39.3197L27.4986 35.1918L28.6822 35.9801L24.9772 41.6015L16.4453 35.8971L20.1195 30.3171L21.3133 31.1158L18.6169 35.2022L20.9737 36.7787L23.2996 33.242L24.4729 34.0302H24.4832Z"
        fill="#393939"/>
      <path
        d="M36.1749 28.6888L34.8576 29.892L31.8009 26.5004L28.4047 29.6016L31.4613 32.9931L30.144 34.1962L23.2588 26.5523L24.5761 25.3492L27.4372 28.5333L30.8335 25.4321L27.9724 22.248L29.2897 21.0449L36.1749 28.6888Z"
        fill="#393939"/>
      <path d="M40.4249 26.1477L38.9017 27.0604L33.6838 18.1822L35.207 17.2695L40.4249 26.1477Z" fill="#393939"/>
      <path
        d="M50.8301 21.8334L49.1525 22.4142L47.6602 18.0892L43.3377 19.6034L44.83 23.9284L43.1525 24.5196L39.7974 14.7702L41.4749 14.1791L42.8746 18.2344L47.2074 16.7201L45.8077 12.6648L47.4853 12.084L50.8404 21.8334H50.8301Z"
        fill="#393939"/>
      <path
        d="M59.5267 11.918L54.8131 12.3328L55.585 21.1799L53.8148 21.3355L52.9194 11.0468L59.3929 10.4763L59.5164 11.918H59.5267Z"
        fill="#393939"/>
      <path
        d="M70.971 17.1244C70.8269 18.1305 70.5284 18.9809 70.0756 19.6862C69.6227 20.3915 69.0464 20.8997 68.3569 21.2108C67.657 21.522 66.8954 21.6153 66.0618 21.4909C65.2385 21.3664 64.5386 21.0553 63.952 20.5574C63.3654 20.0596 62.9537 19.4062 62.717 18.6076C62.4803 17.8089 62.4185 16.917 62.5626 15.942L62.645 15.3716C62.789 14.3759 63.0875 13.5254 63.5506 12.8098C64.0138 12.0941 64.5901 11.5859 65.2899 11.2748C65.9898 10.9636 66.7514 10.8703 67.5747 10.9947C68.398 11.1192 69.1082 11.42 69.6845 11.9178C70.2608 12.4157 70.6725 13.0587 70.9092 13.8573C71.1459 14.6559 71.1974 15.5583 71.0636 16.554L70.9813 17.1244H70.971ZM69.2831 16.3362C69.4478 15.2056 69.3655 14.3033 69.0258 13.6395C68.6862 12.9757 68.1304 12.5816 67.3483 12.4675C66.5867 12.3534 65.9486 12.5712 65.434 13.1106C64.9194 13.6499 64.5798 14.4693 64.4048 15.5687L64.3225 16.1598C64.1578 17.28 64.2505 18.1823 64.6004 18.8565C64.9503 19.5306 65.5061 19.9248 66.2779 20.0388C67.0601 20.1529 67.6982 19.9455 68.2025 19.4062C68.7068 18.8668 69.0464 18.0267 69.2111 16.8755L69.2934 16.3465L69.2831 16.3362Z"
        fill="#393939"/>
      <path
        d="M77.2903 19.904L76.1788 19.4269L74.4498 23.4926L72.8237 22.7873L76.8684 13.3076L78.4945 14.0129L76.7963 17.9956L77.6917 18.3897L82.1583 15.5998L84.1652 16.471L78.9988 19.6136L80.4087 26.0751L78.443 25.2247L77.3006 19.9144L77.2903 19.904Z"
        fill="#393939"/>
      <path
        d="M91.1945 28.1394C90.577 28.938 89.908 29.5395 89.167 29.9233C88.426 30.3174 87.685 30.473 86.9234 30.4004C86.1618 30.3278 85.4517 30.027 84.793 29.5188C84.1344 29.0106 83.6712 28.3883 83.4036 27.6623C83.1361 26.9362 83.0949 26.1687 83.2699 25.3494C83.4448 24.53 83.8359 23.7314 84.4328 22.9432L84.7827 22.4868C85.39 21.6882 86.0692 21.097 86.8205 20.7029C87.5718 20.3088 88.3231 20.1532 89.0847 20.2258C89.8463 20.2984 90.5564 20.5888 91.2151 21.1074C91.8737 21.6156 92.3369 22.2379 92.6045 22.9535C92.872 23.6692 92.9132 24.4367 92.728 25.256C92.5427 26.0754 92.1516 26.8844 91.5444 27.683L91.1945 28.1394ZM90.1139 26.6147C90.8034 25.7124 91.1739 24.8827 91.1945 24.1359C91.2254 23.3891 90.9269 22.7772 90.2991 22.2897C89.6919 21.8126 89.0332 21.6882 88.3231 21.8956C87.613 22.1134 86.9131 22.6528 86.2339 23.524L85.8737 23.9907C85.1841 24.893 84.8239 25.7228 84.8033 26.4799C84.7827 27.237 85.0709 27.8593 85.6884 28.3364C86.3162 28.8239 86.9749 28.9587 87.6747 28.7409C88.3746 28.5231 89.0847 27.9527 89.7845 27.0296L90.1139 26.6044V26.6147Z"
        fill="#393939"/>
      <path
        d="M95.352 33.6674L94.6213 32.7029L91.1324 35.3891L90.0518 33.9682L98.1822 27.6934L99.2629 29.1143L95.846 31.7487L96.4429 32.5266L101.692 32.2984L103.019 34.0408L96.9884 34.2379L95.0741 40.575L93.7774 38.8636L95.3623 33.6674H95.352Z"
        fill="#393939"/>
      <path
        d="M108.145 50.3345L106.807 45.771L104.43 46.4866L105.027 48.5091C105.243 49.2351 105.304 49.8989 105.212 50.5212C105.119 51.1435 104.893 51.6517 104.522 52.0666C104.152 52.4814 103.668 52.7822 103.071 52.9585C102.155 53.2282 101.322 53.1348 100.581 52.6785C99.8395 52.2221 99.3044 51.4443 98.975 50.3656L97.8635 46.58L107.682 43.6448L109.524 49.93L108.145 50.3449V50.3345ZM103.04 46.8911L99.716 47.8868L100.313 49.9093C100.488 50.4901 100.776 50.8946 101.177 51.1227C101.579 51.3509 102.042 51.3924 102.567 51.2368C103.061 51.0916 103.4 50.8116 103.596 50.4071C103.792 50.0026 103.812 49.5255 103.647 48.9551L103.04 46.8911Z"
        fill="#393939"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M107.955 39.315L108.873 42.0407L107.925 42.36L107.328 40.586L96.169 44.3993L96.7504 46.3239L95.7932 46.6131L94.9327 43.765L107.955 39.315Z"
            fill="#393939"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M111.712 54.5865L111.202 51.7557L110.218 51.9328L110.55 53.7753L98.9351 55.8149L98.5165 53.8485L97.5384 54.0567L98.1578 56.9667L111.712 54.5865Z"
            fill="#393939"/>
    </g>
    <defs>
      <clipPath id="clip0_286_554">
        <rect width="102" height="99" fill="white" transform="translate(10 10)"/>
      </clipPath>
    </defs>
  </svg>


</template>

<style scoped lang="scss">
svg {
  width: 100%;
  height: auto;
  display: block;

  .text {
    will-change: rotate;
    transform-origin: center;
    animation: rotate 20s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>