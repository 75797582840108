<script setup>

</script>

<template>
  <section class="section section8" id="section8">
    <div class="content">
      <h2 class="content__title js-scroll  fade-in-title">
        <span class="animation-title ">Чим небезпечна</span>
        <span class="animation-title delay-1">менінгококова інфекція?</span>
      </h2>

      <div class="content__wrapper">
        <div class="js-scroll fade-in-top delay-1-mob content__item">
          <div class="content__icon-text">
            <img class="content__icon" src="@/assets/img/section8/icon-1.svg" alt="">
            <p class="content__icon-caption">Початкові симптоми можуть нагадувати застуду або харчове отруєння.</p>
          </div>
          <p class="content__text">До них належать підвищена температура тіла, головний біль, обмежена рухливість
            шиї,
            може виникати блювання та сплутаність свідомості. Це ускладнює своєчасне встановлення діагнозу та початок
            належного лікування<sup>4,8</sup>.</p>
        </div>

        <div class="js-scroll fade-in-top delay-2-mob content__item">
          <div class="content__icon-text">
            <img class="content__icon" src="@/assets/img/section8/icon-2.svg" alt="">
            <p class="content__icon-caption">У немовлят симптоми можуть бути слабко виражені, що ускладнює їх швидке
              виявлення<sup>4</sup>.</p>
          </div>
        </div>

        <div class="js-scroll fade-in-top delay-3-mob content__item">
          <div class="content__icon-text">
            <img class="content__icon" src="@/assets/img/section8/icon-3.svg" alt="">
            <p class="content__icon-caption">У разі тяжкого перебігу захворювання смерть може настати протягом кількох
              годин після появи перших симптомів<sup>4</sup>.</p>
          </div>
        </div>

        <div class="js-scroll fade-in-top delay-4-mob content__item">
          <div class="content__icon-text">
            <img class="content__icon" src="@/assets/img/section8/icon-4.svg" alt="">
            <p class="content__icon-caption">Навіть при відповідному та своєчасному лікуванні смертність становить
              10-15%<sup>4</sup>.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.content {
  padding-top: 20px;
  padding-bottom: 100px;
  flex-direction: column;

  &__title {
    color: #393939;
    text-align: center;

    font-size: 52px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 2.08px;
    text-transform: uppercase;

    max-width: 800px;
    width: 100%;
    margin: 0 auto;


  }

  &__wrapper {
    margin-top: 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__item {
    max-width: calc((100% / 2) - 10px);
    width: 100%;
    margin-bottom: 20px;

    border-radius: 24px;
    background: #FFF;
    padding: 32px 40px;
  }

  &__icon-text {
    display: flex;
    align-items: flex-start;
  }

  &__icon {
    margin-right: 16px;
    width: 80px;
    height: auto;
    display: block;

  }

  &__icon-caption {
    color: #393939;

    font-size: 24px;
    font-weight: 600;
    line-height: 120%;

    max-width: 400px;
    width: 100%;

  }

  &__text {
    color: #393939;
    margin-top: 9px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;


  }
}

@media screen and (max-width: 1020px) {
  .content {
    padding-top: 0;
    padding-bottom: 80px;

    &__title {
      font-size: 48px;
      line-height: 116%;
      letter-spacing: 1.92px;
      max-width: 710px;
    }

    &__wrapper {
      margin-top: 64px;
    }

    &__item {
      max-width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__icon-text {
    }

    &__icon {

    }

    &__icon-caption {
    }

    &__text {
    }
  }
}

@media screen and (max-width: 600px) {
  .content {
    padding-top: 0;
    padding-bottom: 32px;

    padding-left: 8px;
    padding-right: 8px;

    &__title {
      font-size: 28px;
      line-height: 120%;
      letter-spacing: 1.12px;
      max-width: 290px;
    }

    &__wrapper {
      margin-top: 24px;
    }

    &__icon-text {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__item {
      padding: 16px 24px 24px 24px;

      &:last-of-type {
      }
    }

    &__icon-text {
    }

    &__icon {
      width: 60px;
      margin-right: 0;
    }

    &__icon-caption {
      margin-top: 4px;
      max-width: 100%;
      text-align: center;
      font-size: 18px;

    }

    &__text {
      font-size: 16px;
      margin-top: 5px;
      text-align: center;
    }
  }
}
</style>