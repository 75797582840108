<script setup>

</script>

<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#AA6DE3"/>
    <path d="M24.0929 13.2397L15.9996 21.3331L7.90625 13.2397" stroke="white" stroke-width="1.5" stroke-miterlimit="10"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<style scoped lang="scss">
svg {
  width: 100%;
  height: auto;
  display: block;
  max-width: 32px;
  will-change: transform;
  transition: transform 200ms ease-in-out;

  rect {
    will-change: fill;
    transition: fill 200ms ease-in-out;
  }
}
</style>