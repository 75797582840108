import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/home.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }
        return {top: 0}
    },
})

export default router
