<script>
import {onMounted, ref} from 'vue';

import section1 from "@/components/home/section1.vue";
import section2 from "@/components/home/section2.vue";
import section3 from "@/components/home/section3.vue";
import section4 from "@/components/home/section4.vue";
import section5 from "@/components/home/section5.vue";
import section6 from "@/components/home/section6.vue";
import section7 from "@/components/home/section7.vue";
import section8 from "@/components/home/section8.vue";
import section9 from "@/components/home/section9.vue";
import section10 from "@/components/home/section10.vue";
import section11 from "@/components/home/section11.vue";
import navigation from "@/components/navigation.vue";
import selfMedication from "@/components/self-medication.vue";
import {useStore} from 'vuex'


export default {
  components: {
    navigation,
    selfMedication,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    section8,
    section9,
    section10,
    section11,
  },
  setup() {
    const trigger25 = ref(true),
      trigger50 = ref(true),
      trigger75 = ref(true),
      trigger90 = ref(true);
    const store = useStore()
    const headings = ref([null]);
    const scrollElements = ref([null]);
    const contentShow = ref(false)
    let options = null;

    if (window.matchMedia('(min-width: 1021px)').matches) {
      options = {
        root: document.querySelector(".home"),
        rootMargin: "0px 0px -15% 0px",
        threshold: 0.30,
      };
    } else {
      options = {
        root: document.querySelector(".home"),
        rootMargin: "0px 0px -15% 0px",
        threshold: 0.15,
      };
    }
    let callback = (entries, observer) => {

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let childEl = entry.target.querySelectorAll('.js-scroll')

          childEl.forEach((el) => {
            el.classList.add("scrolled");
          })

          const location = window.location.toString().split('#')[0];
          store.dispatch('setActiveLink', entry.target.id)
          history.replaceState(null, null, location + '#' + entry.target.id);
        }
      });
    };

    let observer = new IntersectionObserver(callback, options);

    onMounted(() => {
      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none'
        contentShow.value = true
      }, 1000)
      headings.value = document.querySelectorAll('.section');
      scrollElements.value = document.querySelectorAll(".js-scroll");

      window.addEventListener("scroll", () => {
        var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

        var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

        if (percent >= 25 && percent < 50 && trigger25.value) {
          trigger25.value = false
          gtag('event', 'scroll', {'depp_scroll': '25%'})
        } else if (percent >= 50 && percent < 75 && trigger50.value) {
          trigger50.value = false
          gtag('event', 'scroll', {'depp_scroll': '50%'})
        } else if (percent >= 75 && percent < 90 && trigger75.value) {
          trigger75.value = false
          gtag('event', 'scroll', {'depp_scroll': '75%'})
        } else if (percent >= 90 && trigger90.value) {
          trigger90.value = false
          gtag('event', 'scroll', {'depp_scroll': '90%'})
        }
      });

      headings.value.forEach((value, index) => {

        observer.observe(headings.value[index]);
      })
    });

    return {headings, scrollElements, contentShow}
  },

  methods: {}
}

</script>
<template>
  <div class="home" v-show="contentShow">
    <section1 class="top"/>
    <section2/>
    <section3/>
    <section4 class="white-bg"/>
    <section5/>
    <section6/>
    <section7 class="white-bg"/>
    <section8/>
    <section9 class="white-bg"/>
    <section10/>
    <section11/>
  </div>
</template>

<style lang="scss">


.white-bg {
  background: white;
}

.section {
  position: relative;
  padding-top: 80px;
  overflow: hidden;

  padding-left: 40px;
  padding-right: 40px;
}

.home {
  background: #F7F3E7;
}

.content {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  position: relative;

  display: flex;
  width: 100%;

  &__title {
    .js-scroll {
      > span {
        display: inline-block;
      }
    }
  }

}

@media screen and (max-width: 600px) {
  .section {
    padding-top: 60px;
    padding-left: 24px;
    padding-right: 24px;

    &.top {
      padding-top: 60px;
    }
  }
}

</style>