<script>
import navigation from '@/components/navigation.vue'
import selfMedication from '@/components/self-medication.vue'
import {ref} from "vue";

export default {
  components: {
    navigation,
    selfMedication
  },
  setup() {
    const mobile = ref(false);

    const getDevice = () => {
      let userAgent = navigator.userAgent || navigator.vendor;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
      }

      return "Unknown";
    }


    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);


    if (getDevice() === 'Android' || getDevice() === 'iOS') {
      mobile.value = true
    }
    return {mobile}

  },
}
</script>
<template>

  <navigation :class="{mobile: mobile}" :activeLink="'section1'"/>
  <self-medication :class="{mobile: mobile}"/>
  <router-view :class="{mobile: mobile}"/>


</template>

<style lang="scss">
@import "@/assets/style/fonts.css";
@import "@/assets/style/vars";
@import "@/assets/style/animation";

.home, .menu, .self-medication {
  &.mobile {
    -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
    outline: none !important;
    cursor: none !important;
  }
}
</style>
