<script setup>

</script>

<template>
  <router-link to="#section1">
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="bg"
        d="M27.8589 63.4548C30.6078 63.8167 33.3922 63.8167 36.1411 63.4548C38.89 63.0929 41.5796 62.3722 44.1411 61.3112C46.7027 60.2502 49.1141 58.858 51.3137 57.1701C53.5134 55.4823 55.4823 53.5134 57.1701 51.3137C58.858 49.1141 60.2502 46.7026 61.3112 44.1411C62.3722 41.5796 63.0929 38.89 63.4548 36.1411C63.8167 33.3922 63.8167 30.6078 63.4548 27.8589C63.0929 25.11 62.3722 22.4204 61.3112 19.8589C60.2502 17.2973 58.858 14.8859 57.1701 12.6863C55.4823 10.4866 53.5134 8.51773 51.3137 6.82988C49.1141 5.14203 46.7027 3.74981 44.1411 2.68878C41.5796 1.62775 38.89 0.907085 36.1411 0.545189C33.3922 0.183292 30.6078 0.183292 27.8589 0.545189C25.11 0.907085 22.4204 1.62775 19.8589 2.68878C17.2974 3.74981 14.8859 5.14203 12.6863 6.82988C10.4866 8.51773 8.51773 10.4866 6.82988 12.6863C5.14204 14.8859 3.74981 17.2973 2.68878 19.8589C1.62775 22.4204 0.907084 25.11 0.545187 27.8589C0.18329 30.6078 0.18329 33.3922 0.545187 36.1411C0.907084 38.89 1.62775 41.5796 2.68878 44.1411C3.74981 46.7027 5.14203 49.1141 6.82989 51.3137C8.51774 53.5134 10.4866 55.4823 12.6863 57.1701C14.8859 58.858 17.2973 60.2502 19.8589 61.3112C22.4204 62.3722 25.11 63.0929 27.8589 63.4548Z"
        fill="#E5DCC0"/>
      <path d="M41.4401 32.6466L31.5735 22.5596L21.7068 32.6466M31.5735 42.293V22.8421" stroke="#022E4E"
            stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </router-link>

</template>

<style scoped lang="scss">
a {
  @media screen and (min-width: 1024px) {
    &:hover {
      .bg {
        fill: #FF8DBE;
      }
    }
  }

  :active {
    .bg {
      fill: #AA6DE3;
    }
  }
}

svg {
  width: 100%;
  height: auto;
  display: block;

  .bg {
    will-change: fill;
    transition: fill 200ms ease-in-out;
  }
}
</style>